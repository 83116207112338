// src/components/MatrixNineBox.tsx

import React, { useEffect, useState, useContext } from 'react';
import './MatrixNineBox.css';
import { AuthContext } from "../../contexts/auth";
import { IColaboradorNineBox } from '../../interfaces/ICadastroUsuario';
import { Button, Typography, Select, MenuItem, FormControl, Box, InputLabel, Stack, Snackbar, SelectChangeEvent } from '@mui/material';

interface Box {
  title: string;
  collaborators: IColaboradorNineBox[];
}

const boxTitles: { [key: number]: string } = {
  1: '1 Insuficientes',
  2: '2 Trabalhar Valores',
  3: '3 Verificar Situação',
  4: '4 Desenvolver Comportamento',
  5: '5 Desenvolver Técnica',
  6: '6 Aprimorar Comp. e Técnica',
  7: '7 Aprimorar Comportamento',
  8: '8 Aprimorar Técnica',
  9: '9 Destaques',
};

const MatrixNineBox: React.FC = () => {
  const [boxes, setBoxes] = useState<{ [key: number]: Box }>({});
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const { getNineBoxFiltro, getDiretorias, getDepartamentos, getCargos, getLideres } = useContext(AuthContext);

  const [diretorias, setDiretorias] = useState<{ID:number, Nome:string}[]>([]);
  const [lideres, setLideres] = useState<{ID:number, Nome:string}[]>([]);
  const [departamentos, setDepartamentos] = useState<(string|null)[]>([]);
  const [cargos, setCargos] = useState<(string|null)[]>([]);

  const [selectedDiretoria, setSelectedDiretoria] = useState('');
  const [selectedDepartamento, setSelectedDepartamento] = useState('');
  const [selectedCargo, setSelectedCargo] = useState('');
  const [selectedLiderImediato, setSelectedLiderImediato] = useState('');

  // Novo estado para guardar total de colaboradores
  const [totalColaboradores, setTotalColaboradores] = useState(0);

  // Lógica para calcular qual box o colaborador deve ir
  function calculateBoxNum(comportamento: number, desempenho: number): number {
    if (comportamento === 1 && desempenho === 1) return 1;
    if (comportamento === 1 && desempenho === 2) return 2;
    if (comportamento === 1 && desempenho === 3) return 4;
    if (comportamento === 2 && desempenho === 1) return 3;
    if (comportamento === 2 && desempenho === 2) return 6;
    if (comportamento === 2 && desempenho === 3) return 7;
    if (comportamento === 3 && desempenho === 1) return 5;
    if (comportamento === 3 && desempenho === 2) return 8;
    if (comportamento === 3 && desempenho === 3) return 9;
    return 0;
  }

  useEffect(() => {
    // Exemplos de fetch das listas e dados iniciais
    async function fetchDiretorias() {
      let diretorias = await getDiretorias();
      setDiretorias(diretorias);
    }
    
    async function fetchDepartamentos() {
      let departamentos = await getDepartamentos();
      setDepartamentos(departamentos);
    }
    async function fetchCargos() {
      let cargos = await getCargos();
      setCargos(cargos);
    }
    async function fetchLideres() {
      let lideres = await getLideres();
      setLideres(lideres);
    }
    async function fetchData() {
      const filterJson = {
        Diretoria: selectedDiretoria === '' ? null : Number(selectedDiretoria),
        Departamento: selectedDepartamento,
        Cargo: selectedCargo,
        Lider_Imediato: selectedLiderImediato === '' ? null : Number(selectedLiderImediato)
      };

      let data = await getNineBoxFiltro(filterJson);
      updateBoxes(data);
    }

    fetchData();
    fetchDiretorias();
    fetchDepartamentos();
    fetchCargos();
    fetchLideres();
  }, []);

  const handleFilter = async () => {

    const filterJson = {
      Diretoria: selectedDiretoria === '' ? null : Number(selectedDiretoria),
      Departamento: selectedDepartamento,
      Cargo: selectedCargo,
      Lider_Imediato: selectedLiderImediato === '' ? null : Number(selectedLiderImediato)
    };

    console.log(filterJson);
    let data = await getNineBoxFiltro(filterJson);
    updateBoxes(data);
  };

  // Responsável por agrupar e atualizar estado boxes + total
  const updateBoxes = (data: IColaboradorNineBox[]) => {
    const groupedBoxes: { [key: number]: Box } = {};
    for (let boxNum = 1; boxNum <= 9; boxNum++) {
      groupedBoxes[boxNum] = {
        title: boxTitles[boxNum],
        collaborators: [],
      };
    }

    // Agrupa colaboradores nos boxes
    data.forEach((collaborator: IColaboradorNineBox) => {
      const boxNum = calculateBoxNum(collaborator.comportamento, collaborator.desempenho);
      groupedBoxes[boxNum].collaborators.push(collaborator);
    });

    // Atualiza o estado principal
    setBoxes(groupedBoxes);
    // Atualiza total de colaboradores
    setTotalColaboradores(data.length);
  };

  // Handlers de selects
  const handleSelectChangeDiretoria = (event: SelectChangeEvent) => {
    setSelectedDiretoria(event.target.value);
  };
  const handleSelectChangeLider = (event: SelectChangeEvent) => {
    setSelectedLiderImediato(event.target.value);
  };
  const handleSelectChangeDepartamento = (event: SelectChangeEvent) => {
    setSelectedDepartamento(event.target.value);
  };
  const handleSelectChangeCargo = (event: SelectChangeEvent) => {
    setSelectedCargo(event.target.value);
  };

  // Popup
  const [noScroll, setNoScroll] = useState(false);
  useEffect(() => {
    if (selectedBox !== null) {
      document.body.classList.add('no-scroll');
      setNoScroll(true);
    } else {
      document.body.classList.remove('no-scroll');
      setNoScroll(false);
    }
  }, [selectedBox]);

  const renderBox = (boxNumber: number) => {
    const box = boxes[boxNumber];
    if (!box) return null;

    // Colaboradores a serem mostrados (limite de 10)
    const collaboratorsToShow = box.collaborators.slice(0, 10);
    const hasMore = box.collaborators.length > 10;

    // Cálculo de percentual em relação ao total
    const boxCount = box.collaborators.length;
    const percentage = totalColaboradores > 0 
      ? ((boxCount / totalColaboradores) * 100).toFixed(2) 
      : '0';

    return (
      <div className={`box box-${boxNumber}`} key={boxNumber}>
        {/* Exemplo: "1 Insuficientes (10 / 5%)" */}
        <h3>
          {box.title} ({boxCount} / {percentage}%)
        </h3>

        <div className="photos">
          {collaboratorsToShow.map((collaborator) => (
            <div className="photo" key={collaborator.id}>
              <img
                src={collaborator.usuario.urL_Foto}
                alt={collaborator.usuario.username}
                title={collaborator.usuario.username}
              />
            </div>
          ))}
        </div>
        {hasMore && (
          <button onClick={() => setSelectedBox(boxNumber)} className="see-more">
            Ver mais
          </button>
        )}
      </div>
    );
  };

  const renderPopup = () => {
    if (selectedBox === null) return null;

    const box = boxes[selectedBox];
    const handleClose = () => {
      setSelectedBox(null);
    };

    return (
      <div className="popup" onClick={handleClose}>
        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
          <h2>{box.title}</h2>
          <ul>
            {box.collaborators.map((collaborator) => (
              <li key={collaborator.id}>
                <b>{collaborator.usuario.username}</b> - {collaborator.usuario.departamento}/{collaborator.usuario.cargo}
              </li>
            ))}
          </ul>
          <button onClick={handleClose}>Fechar</button>
        </div>
      </div>
    );
  };

  return (
    <div className="matrix-nine-box">
      {/* Filtros */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        {/* Diretoria */}
        <FormControl variant="outlined" sx={{ minWidth: 200, marginRight: 2 }}>
          <InputLabel id="diretoria-label">Diretoria</InputLabel>
          <Select
            labelId="diretoria-label"
            id="diretoria-select"
            label="Diretoria"
            value={selectedDiretoria}
            onChange={handleSelectChangeDiretoria}
          >
            {diretorias.map((option) => (
              <MenuItem key={option.ID} value={option.ID}>
                {option.Nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Departamento */}
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel id="departamento-label">Departamento</InputLabel>
          <Select
            labelId="departamento-label"
            id="departamento-select"
            label="Departamento"
            value={selectedDepartamento}
            onChange={handleSelectChangeDepartamento}
          >
            {departamentos
              .filter((option) => option !== null)
              .map((option, index) => (
                <MenuItem key={index} value={option!}>
                  {option}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        {/* Cargo */}
        <FormControl variant="outlined" sx={{ minWidth: 200, marginLeft: 2 }}>
          <InputLabel id="cargo-label">Cargo</InputLabel>
          <Select
            labelId="cargo-label"
            id="cargo-select"
            label="Cargo"
            value={selectedCargo}
            onChange={handleSelectChangeCargo}
          >
            {cargos
              .filter((option) => option !== null)
              .map((option, index) => (
                <MenuItem key={index} value={option!}>
                  {option}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        {/* Líder */}
        <FormControl variant="outlined" sx={{ minWidth: 200, marginLeft: 2 }}>
          <InputLabel id="lider-label">Líder</InputLabel>
          <Select
            labelId="lider-label"
            id="lider-select"
            label="Líder"
            value={selectedLiderImediato}
            onChange={handleSelectChangeLider}
          >
            {lideres.map((option) => (
              <MenuItem key={option.ID} value={option.ID}>
                {option.Nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleFilter} 
          sx={{ marginLeft: 2 }}
        >
          Filtrar
        </Button>
      </Box>

      {/* Popup */}
      {renderPopup()}

      {/* Matriz */}
      <div className="matrix-container">
        <div className="left-labels">
          <div>Acima das Expectativas</div>
          <div>Dentro das Expectativas</div>
          <div>Abaixo das Expectativas</div>
        </div>
        <div className="matrix-grid">
          {[5, 8, 9, 3, 6, 7, 1, 2, 4].map((boxNumber) => renderBox(boxNumber))}
        </div>
        <div className="bottom-labels">
          <div>Abaixo das Expectativas</div>
          <div>Dentro das Expectativas</div>
          <div>Acima das Expectativas</div>
        </div>
      </div>
    </div>
  );
};

export default MatrixNineBox;
